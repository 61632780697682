import React from 'react';
import AccessForm from "./component.programme-access-form";

const ProgrammeListItem = ({ programme }) => (
    <div key={programme.slug} className="col-12">
        <div className="c-programme-list__item xs-pt-40 xs-pb-40">
            <div className="row">
                <div className="col-md-8 offset-md-2 col-10 offset-1">
                    <div>
                        <h2 className="c-programme-list__title">{programme.title}</h2>
                        <div dangerouslySetInnerHTML={{ __html: programme.ACFProgrammeFields.description }}></div>
                        <AccessForm programme={programme}/>
                    </div>
                </div>
            </div>
        </div>
    </div>
);

export default ProgrammeListItem;
