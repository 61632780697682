import React, {useEffect, useState} from 'react';
import { graphql } from 'gatsby';

import Layout from '../components/component.layout';
import SEO from '../components/component.seo';
import HeroBanner from '../components/hero-banner/component.banner';
import CookieNotice from "../components/component.cookie-notice";
import useProgrammesData from "../hooks/hook.useProgrammesData";
import ProgrammeList from "../components/programme-list/component.programme-list";

const ProgrammeListTemplate = ({ data }) => {
    const { title, featuredImage, seo, ACFFeaturedVideoBlockFields: video, language, translations, slug } = data.wordpress.page;
    const bannerImage = featuredImage ? featuredImage.node.imageFile : false;
    
    const allProgrammesData = useProgrammesData();
    let programmesData = [];

    let i;
    for (i in allProgrammesData) {
        // only use programmes that match the page's language:
        if(language.code == allProgrammesData[i].ACFProgrammeFields.language) {
            programmesData.push(allProgrammesData[i]);
        }
    }

    const [programmes, setProgrammes] = useState([]);
    
    useEffect(() => {
        setProgrammes(programmesData);
    }, []);
    
    return (
        <Layout language={language} translations={translations}>
            <SEO title={title} seo={seo} image={featuredImage} translations={translations} slug={slug} language={language} />
            <HeroBanner title={title} image={bannerImage} video={video} />
            
            <ProgrammeList programmes={programmes} language={language} />
            
            <CookieNotice />
        </Layout>
    );
};

export const query = graphql`
    query($id: ID!) {
        wordpress {
            page(id: $id) {
                title
                slug
                content
                language {
                    code
                    locale
                }
                # Get links to the translated versions of each page
                # This is an array of post objects
                translations {
                    # id
                    uri
                    language {
                        code
                        locale
                    }
                }
                featuredImage {
                    node {
                        altText
                        sourceUrl
                        imageFile {
                            childImageSharp {
                                fluid(maxWidth: 1920) {
                                    ...GatsbyImageSharpFluid_withWebp_tracedSVG
                                }
                            }
                        }
                    }   
                }
                seo {
                    ...SeoPostFields
                }
                ...PageBlocks
            }
        }
    }
`;

export default ProgrammeListTemplate;
