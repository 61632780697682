import React from 'react';
import ProgrammeListItem from "./component.programme-list-item";

const ProgrammeList = ({ programmes }) => (
    <div className="u-bg--wild-sand xs-pt-30 xs-pb-30 lg-pt-40 lg-pb-40">
        <div className="container">
            <div className="row">
                {programmes.map((programme, index) => (
                    <ProgrammeListItem key={index} programme={programme} />
                ))}
            </div>
        </div>
    </div>
);

export default ProgrammeList;
